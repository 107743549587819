import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import { Spinner } from "spin.js";

import "../css/style.scss";

require("fancybox")($);
require("slick-carousel");

// import "./assets/css/style.scss";

$(document).ready(function() {
  var $window = $(window);

  // Set up listeners for TV guides.
  $(".tv-carousel").each(function() {
    var $carousel = $(this);
    var $daySelector = $(".filter ul.tabs", $carousel);
    var $tabs = $daySelector.children();
    var $slides = $(".view", $carousel);
    var $prev = $(".controls .prev", $carousel);
    var $next = $(".controls .next", $carousel);
    var $channelSelect = $(".select select", $carousel);

    var $controls = $prev.add($next);

    var cache = {};
    var meta = {};
    var currentSlides = [];
    var currentContainers = 0;
    var loading = false;
    var jumped = false;
    var date = 0; // Today
    var selectedChannel = null;

    $channelSelect.change(changeChannel);

    $slides.on("click", "table .program", getProgramInfo);
    $daySelector.on("click", "a", setDay);
    $daySelector
      .children()
      .first()
      .addClass("active");

    // Toggle between short and long tab labels when viewport width changes
    var $daySelectorLinks = $("a", $daySelector);
    var smallScreenWidth = 500;
    var isScreenSmall = $window.width() < smallScreenWidth;

    function updateLabels() {
      $daySelectorLinks.each(function() {
        var $link = $(this);
        var label = $link.data("label");
        var labelShort = $link.data("labelshort");
        var displayLabel = isScreenSmall ? labelShort : label;
        $link.text(displayLabel);
      });
    }

    function toggleTabLabels() {
      var isNewScreenSmall = $window.width() < smallScreenWidth;
      if (isScreenSmall !== isNewScreenSmall) {
        isScreenSmall = isNewScreenSmall;
        updateLabels();
      }
    }

    $window.on("resize scroll", _.throttle(toggleTabLabels, 200));

    updateLabels();

    // Set up Slick.js.
    $slides.slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      arrows: true,
      dots: false,
      adaptiveHeight: true,
      prevArrow: $prev,
      nextArrow: $next
      // responsive: [
      //   {
      //     breakpoint: 1020,
      //     settings: {
      //       slidesToShow: 3,
      //       slidesToScroll: 3
      //     }
      //   },
      //   {
      //     breakpoint: 900,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 2
      //     }
      //   },
      //   {
      //     breakpoint: 600,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1
      //     }
      //   }
      // ]
    });

    $slides.on("beforeChange", onSlickChange);

    getChannelsMeta();

    // Gets meta information of all the channels in the EPG.
    function getChannelsMeta() {
      setLoading(true);
      $.getJSON("/api/channels", function(data) {
        meta = data;
        lazyLoadChannels(0);
      });
    }

    // Event handler for day selector tabs.
    function setDay(e) {
      e.preventDefault();
      var $li = $(e.target).parents("li");
      var index = $tabs.index($li);

      if (date !== index) {
        date = index;
        changeDay();
      }

      setActiveClass(e);
    }

    function changeDay() {
      $(".slick-track", $slides).empty();
      currentSlides = [];
      currentContainers = 0;
      setLoading(true);
      if (selectedChannel !== null) {
        lazyLoadChannels(selectedChannel).then(function(res) {
          jumpToSlide(selectedChannel);
        });
      } else {
        lazyLoadChannels(0);
      }
    }

    function changeChannel() {
      var val = $(this).val();
      if (!isNaN(val)) {
        setLoading(true);
        var idx = _.findIndex(meta, { id: val });
        selectedChannel = idx;

        lazyLoadChannels(idx).then(function(res) {
          jumpToSlide(idx);
        });
      }
    }

    function jumpToSlide(idx) {
      jumped = true;
      $slides.slick("slickGoTo", idx);
    }

    // Event handler for slick changes.
    function onSlickChange(e, slick, current, newIdx) {
      selectedChannel = newIdx;
      if (!jumped) {
        setLoading(true);
        lazyLoadChannels(newIdx);
      } else {
        jumped = false;
      }
    }

    function setLoading(val) {
      loading = val;

      var options = {
        color: "#cd0100",
        top: "250px"
      };

      $controls.toggleClass("inactive", loading);

      // If loading is now true, add loading screen,
      // else remove loading screen.
      if (loading) {
        var $spinner = $("<div />").addClass("spinner-container");
        var spinner = new Spinner(options).spin();

        $spinner.append(spinner.el);
        $carousel.append($spinner);
      } else {
        $(".spinner-container", $carousel).remove();
      }
    }

    // Lazy loads program information for the next few channels.
    function lazyLoadChannels(index, padding) {
      index = index || 0;
      padding = padding || 6;

      // Determine which channels should be loaded.
      var start = index - padding;
      var end = index + padding;
      var channelIds = [];

      // Make sure start index is never less than 0 and
      // end is never higher than the number of channels.
      start = start < 0 ? 0 : start;
      end = end >= meta.length ? meta.length : end;

      // Build array of channel IDs around current index.
      for (start; start <= end; start++) {
        if (meta[start]) {
          channelIds.push(meta[start].id);
        }
      }

      var deferred = $.Deferred();

      if (currentContainers < meta.length) {
        var lastSlideIdx = end + 1 <= meta.length ? end + 1 : meta.length;
        $.when(createDummySlides(lastSlideIdx), getData(channelIds)).then(
          function(dummyDone, channelData) {
            if (channelData) {
              buildHtml(channelData, channelIds);
            }

            setLoading(false);

            deferred.resolve();
          }
        );
      } else {
        setLoading(false);
        deferred.resolve();
      }

      return deferred;
    }

    // Builds the DOM element for a slide/channel and adds it to Slick.js.
    function buildHtml(newChannels, requestedChannelIds) {
      // Figure out which channels don't have any data
      var cachedChannelIds = _.keys(cache[date]);

      var missingChannelIds = _.reject(requestedChannelIds, function(id) {
        return _.includes(cachedChannelIds, String(id));
      });

      // Render empty channels
      _.each(missingChannelIds, function(missingChannelId) {
        var channelIndex = _.findIndex(meta, { id: missingChannelId });
        if (channelIndex > -1) {
          var $img = $("<img />").attr({
            src: meta[channelIndex].imagepath
          });

          var $p = $("<p />").text(
            "Op dit moment is er geen programma informatie beschikbaar."
          );

          var $div = $('<div class="epg-no-data" />').append($img, $p);

          $slides
            .find(".slide")
            .eq(channelIndex)
            .html($div);
        }
      });

      // Render new channels that do have data
      _.each(newChannels, function(channel) {
        var $table = $("<table />");
        var $thead = $("<thead />");
        var $tbody = $("<tbody />");

        var channelMeta = _.find(meta, { id: channel.channel_id });
        var idx = _.findIndex(meta, { id: channel.channel_id });

        var $tr = $("<tr />");

        var $th = $("<th />").appendTo($tr);
        if (channelMeta.imagepath) {
          var $img = $("<div />")
            .addClass("channel-logo")
            .css({
              backgroundImage: "url(" + channelMeta.imagepath + ")"
            });
          $th.append($img);
        }

        var $th2 = $("<th />");

        var $channelName = $("<span />").text(channelMeta.name);
        $th2.append($channelName);
        $tr.append($th2);

        $thead.append($tr);
        $table.append($thead);

        _.each(channel.programs, function(program, index) {
          var $row = $("<tr />").addClass("program");

          var startDate = moment(program.starttime);

          var $time = $("<th />")
            .addClass("time")
            .html(startDate.format("HH:mm"));

          // Check if program is a tip.
          if (program.article_medium || program.article_long) {
            var $tip = $("<span />")
              .addClass("tip")
              .text("Tip");
            $time.append($tip);

            $row.addClass("is-tip");
          }

          var $program = $("<td />")
            .addClass("program")
            .text(program.title);

          $row.append($time, $program);
          $row.attr("data-id", program.id);

          $tbody.append($row);
        });

        $table.append($tbody);

        var $wrap = $('<div class="table-wrap" />').append($table);

        $slides
          .find(".slide")
          .eq(idx)
          .html($wrap);
      });
    }

    function getData(channelIds) {
      var deferred = $.Deferred();

      // Filter out the slides that are already in the DOM.
      var toBeAdded = _.reject(channelIds, function(id) {
        return _.includes(currentSlides, id);
      });

      if (toBeAdded.length > 0) {
        var toBeFetched = toBeAdded;
        var cached = {};

        if (cache[date]) {
          // Check if some of these channels are already in cache.
          toBeFetched = _.reject(toBeAdded, function(id) {
            return cache[date][id];
          });

          cached = _.filter(cache[date], function(val, key) {
            return _.includes(toBeAdded, key);
          });
        }

        if (toBeFetched.length > 0) {
          // Check if amount of channels to be loaded is larger than padding,
          // this means it's either the initial load or jumping to a channel.

          toBeFetched = toBeFetched.join();
          var url = `/api/programsbychannel?date=${date}&channels=${toBeFetched}`;

          $.getJSON(url, function(data) {
            if (!cache[date]) {
              cache[date] = data;
            } else {
              cache[date] = _.extend(cache[date], data);
            }

            data = _.extend(data, cached);
            currentSlides = _.union(currentSlides, toBeAdded);

            deferred.resolve(data);
          });
        } else {
          currentSlides = _.union(currentSlides, toBeAdded);
          deferred.resolve(cached);
        }
      } else {
        deferred.resolve(false);
      }

      return deferred;
    }

    function createDummySlides(count) {
      var deferred = $.Deferred();

      var amount = count - currentContainers;

      if (amount > 0) {
        _.defer(function() {
          currentContainers += amount;
          var $container = $("<div />").addClass("slide");

          for (var i = 0; i < amount; i++) {
            var $clone = $container.clone();
            $slides.append($clone);
            $slides.slick("slickAdd", $clone);
          }

          deferred.resolve();
        });
      } else {
        deferred.resolve();
      }

      return deferred;
    }

    function getProgramInfo(e) {
      e.stopImmediatePropagation();

      var $program = $(e.target.closest("tr"));
      var programId = $program.eq(0).data("id");

      setLoading(true);

      $.getJSON(`/api/program?id=${programId}`, function(data) {
        setLoading(false);

        var lightboxHtml = "";
        if (data.images && data.images[0]) {
          lightboxHtml += '<img src="' + data.images[0].filepath + '">';
        }

        lightboxHtml +=
          '<div class="program-lightbox"><h2>' + data.title + "</h2>";

        var starttime = moment(data.starttime).format("HH:mm");
        var endtime = moment(data.endtime).format("HH:mm");
        var channel = _.find(meta, { id: data.channel_id });

        lightboxHtml +=
          '<div class="broadcast-info"> Op ' +
          channel.name +
          " om " +
          starttime +
          " tot " +
          endtime +
          "</div>";

        if (data.type) {
          lightboxHtml += '<div class="program-type">' + data.type + "</div>";
        }

        if (data.synopsis_long) {
          lightboxHtml += "<p>" + data.synopsis_long + "</p>";
        } else if (data.synopsis_medium) {
          lightboxHtml += "<p>" + data.synopsis_medium + "</p>";
        } else if (data.synopsis_short) {
          lightboxHtml += "<p>" + data.synopsis_short + "</p>";
        }

        lightboxHtml += "</div>";

        $.fancybox.open(lightboxHtml, {
          maxWidth: 600,
          padding: 0
        });
      });
    }
  });

  // Click listener for navigation tabs within TV guides.
  function setActiveClass(e) {
    e.preventDefault();
    var $thisTab = $(e.target).parent();

    $thisTab.siblings().removeClass("active");

    $thisTab.addClass("active");
  }
});
